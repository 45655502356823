import axios from 'axios';
import { BinCollection } from '../redux/types';

export const fetchBinDays = async (): Promise<BinCollection[]> => {
  return (await axios.get<BinCollection[]>(
    'https://moistsquid.co.uk/binDays/data.json',
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
  )).data;
}

