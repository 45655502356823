import { Divider, Grid, Text} from '@nextui-org/react';
import { connect, ConnectedProps } from 'react-redux';
import { StoreState} from '../../redux/store'
import { BinCollectionDateCard } from '../BinCollectionDateCard';
type OwnProps = {};

const mapStateToProps = (state: StoreState) => {
  return {
    monthlyCollections: state.binCollections.monthlyCollections
  }
}

const connector = connect(mapStateToProps)

type BinCollectionsListProps = OwnProps & ConnectedProps<typeof connector>

const monthTitles = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const BinCollectionsList = (props: BinCollectionsListProps) => {
  const { monthlyCollections } = props;

  return (
    <>
    {
      Object.entries(monthlyCollections).map(([monthIdx, collections]) => {
        return (
          <Grid.Container gap={2} key={`month-${monthIdx}`}>
            <Grid>
              <Text h3>{monthTitles[parseInt(monthIdx, 10)]}</Text>
            </Grid>
            <Divider />
            {
              collections.map((props) => (<BinCollectionDateCard {...props} key={props.collectionDate.toISOString()}/>)) 
            }
          </Grid.Container>
        )
      })
    }
    </>
  )
}

export default connector(BinCollectionsList)



