import { createAction, createReducer } from '@reduxjs/toolkit'
import { CollectionDate, BinCollectionsState } from '../types';

const setBinCollections = createAction<CollectionDate[]>('binCollections/set');

const initialBinCollections: BinCollectionsState = {
  upcomingCollections: [],
  monthlyCollections: {},
  nextCollection: undefined,
}

export const binCollectionsReducer = createReducer(initialBinCollections, (builder) => {
  builder
    .addCase(setBinCollections, (_, action) => {
      const upcomingCollections = action.payload.sort(({collectionDate: a}, { collectionDate: b}) => a.toISOString().localeCompare(b.toISOString()));

      const monthlyCollections = upcomingCollections.reduce<Record<string, CollectionDate[]>>((acc, curr) => {
        const month = curr.collectionDate.getMonth()
        return {
          ...acc,
          [month]: [...acc[month] || [] , curr]
        }
      }, {});

      
      return {
        upcomingCollections,
        monthlyCollections,
        nextCollection: upcomingCollections[0],
      }
    });
});

