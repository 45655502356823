import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid, Text } from '@nextui-org/react';

import { StoreDispatch } from '../../redux/store';
import { CollectionDate } from '../../redux/types';

import { fetchBinDays } from '../../services/fetchBinDays';

import { BinCollectionsList } from '../../components/BinCollectionsList';
import NextBinCollectionDisplay from '../../components/NextBinCollectionDisplay/NextBinCollectionDisplay';
import MainLayout from '../../layouts/MainLayout';

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setBinCollections: (collectionDates: CollectionDate[]) => {
    return dispatch({ type: 'binCollections/set', payload: collectionDates})
  }
})

const connector = connect(null, mapDispatchToProps)

type HomePageProps = ConnectedProps<typeof connector>

const HomePage = (props: HomePageProps) => {
  const { setBinCollections } = props;

  useEffect(() => {

    fetchBinDays().then((binCollections) => {
      const collectionDates: CollectionDate[] = binCollections.map(({ binColour, nextCollections }) => (
        nextCollections.map((collectionDate) => ({ binColour, collectionDate: new Date([...collectionDate.split(' '), 'GMT' ] as any)}))
        ))
        .flat();
        
        setBinCollections(collectionDates);
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <Grid.Container gap={2}>
      <NextBinCollectionDisplay />
      </Grid.Container>
      <Grid.Container gap={2}>
        <Grid xs={15}>
          <Text h2>Upcoming collections</Text>
        </Grid>
        <Grid>
          <BinCollectionsList />
        </Grid>
      </Grid.Container>
    </MainLayout>
  )
}

export default connector(HomePage);
