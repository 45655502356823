import { Card, Grid } from "@nextui-org/react";
import { BinColour } from "../../redux/types";

export type BinCollectionDateCardProps = {
  binColour: BinColour
  collectionDate?: Date;
  textOverride?: string;
}

export const BinCollectionDateCard = (props: BinCollectionDateCardProps) => {

  const { binColour, collectionDate, textOverride } = props;

  return (
    <Grid>
      <Card css={{ background:`$binColour${binColour}`, color: '$white'}}>
        <Card.Body>
          { textOverride || (collectionDate ? collectionDate.toISOString().slice(0,10) : binColour)}
        </Card.Body>
      </Card>
    </Grid>
  )
}