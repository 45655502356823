import { Link as RouterLink } from 'react-router-dom'
import { Grid, Switch, SwitchEvent, Text, Divider } from '@nextui-org/react';
import { BiSun, BiMoon } from 'react-icons/bi'

import { useTheme } from "../../hooks/useTheme";

export const GRID_TEXT_GRADIENT = '45deg, #AAFFEC -77%, #ff4ecd 20%, #0070F3 120%';
export const GRID_TEXT_HOVER_GRADIENT = '45deg, #88ddba -77%, #dd1ba9 20%, #0050d1 120%';
export const TEXT_GRADIENT = '90deg, #AAFFEC -20%, #ff4ecd 0%, #0070F3 20%';

export const Header = () => {

  const { setTheme } = useTheme();

  const handleThemeChange = (event: SwitchEvent) => {
    const { target: { checked }} = event;
    setTheme(checked ? 'light' : 'dark')
  }

  return (
    <nav>
      <Grid.Container justify='space-between' gap={1} alignItems='baseline'>
        <Grid xs={3}>
          <RouterLink to='/'>
            <Text
              weight='bold'
              size={40}
              css={{
                textGradient: GRID_TEXT_GRADIENT,
              }}
            >
              MoistSquid
            </Text>
          </RouterLink>
        </Grid>
        
        <Grid xs={9}>
          <Grid.Container justify='flex-end' gap={1} alignItems='center'>
            
            <Grid>
              <Switch
                color='warning'
                size='lg'
                onChange={handleThemeChange}
                iconOn={<BiSun />}
                iconOff={<BiMoon />}
                css={{
                  color: '$pink600',
                  '[data-state="checked"]': {
                    background: `linear-gradient(${GRID_TEXT_GRADIENT})`,
                  },
                  '[data-state="checked"]:hover': {
                    background: `linear-gradient(${GRID_TEXT_HOVER_GRADIENT}) !important`
                  }
                }}
              />
            </Grid>
          </Grid.Container>
        </Grid>
      </Grid.Container>
      <Divider />
    </nav>
  )
}

export default Header;