import { Card, Divider, Grid, Text } from '@nextui-org/react';
import { connect, ConnectedProps } from 'react-redux';
import { StoreState} from '../../redux/store'
import { BinCollectionDateCard } from '../BinCollectionDateCard';

type OwnProps = {};

const mapStateToProps = (state: StoreState) => {
  return {
    nextCollection: state.binCollections.nextCollection,
  }
}

const connector = connect(mapStateToProps)

const binOutStrings: Record<string, string> = {
  '0': 'This Morning',
  '1': 'Tonight',
}

type NextBinCollectionDisplayProps = OwnProps & ConnectedProps<typeof connector>

const NextBinCollectionDisplay = (props: NextBinCollectionDisplayProps) => {
  const { nextCollection } = props;

  const binColour = nextCollection?.binColour;
  const collectionDate = nextCollection?.collectionDate;

  
  if(!binColour || ! collectionDate) {
    return (
      <p> Loading Bin Data</p>
      )
    }
    
  const daysAway = Math.ceil( (collectionDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24))
  const binOutString = binOutStrings[`${daysAway}`] || 'A few days'

  return (
    <Grid.Container gap={2}>
      <Grid xs={12} sm={6}>
        <Card>
          <Card.Header>
            <Text h3>Todays date is:</Text>
          </Card.Header>
          <Divider />
          <Card.Body>
            <BinCollectionDateCard
              binColour={binColour}
              collectionDate={new Date()}
            />
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={12} sm={6}>
        <Card>
          <Card.Header>
            <Text h3> Next bin colour:</Text>
          </Card.Header>
          <Divider />
          <Card.Body>
            <BinCollectionDateCard
              binColour={binColour}
            />
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={12} sm={6}>
        <Card>
          <Card.Header>
            <Text h3>Next collection is on:</Text>
          </Card.Header>
          <Divider />
          <Card.Body>
            <BinCollectionDateCard
              binColour={binColour}
              collectionDate={collectionDate}
            />
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={12} sm={6}>
        <Card>
          <Card.Header>
            <Text h3>Next collection is in:</Text>
          </Card.Header>
          <Divider />
          <Card.Body>
          <BinCollectionDateCard
              binColour={binColour}
              textOverride={`${daysAway} Days`}
            />
          </Card.Body>
        </Card>
      </Grid>

      <Grid xs={12} sm={6}>
        <Card>
          <Card.Header>
            <Text h3>Put the bin out in:</Text>
          </Card.Header>
          <Divider />
          <Card.Body>
          <BinCollectionDateCard
              binColour={binColour}
              textOverride={binOutString}
            />
          </Card.Body>
        </Card>
      </Grid>
        
    </Grid.Container>
  )
}

export default connector(NextBinCollectionDisplay)



