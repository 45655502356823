import { HomePage } from "./pages/HomePage";
import {
  Routes,
  Route,
} from "react-router-dom";


import { ThemeProvider } from "./Providers/ThemeProvider";
import { Header } from "./components/Header";

function App() {
  return (
    <ThemeProvider>
      <>
        <Header />
        <div className="content">
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='*' element={<HomePage />} />
          </Routes>
          </div>
      </>
    </ThemeProvider>
  );
}

export default App;
